import React from 'react';
import { NavLink } from 'react-router-dom';
import photographyAlbums from "../photographyalbums.json";

const NavbarPhotography = () => {
  return (
    <div>
        <ul className='photographyAlbumNav'>
          {photographyAlbums && photographyAlbums.map((album, index) => {
            return(
              <NavLink to={album.link} key={index} aria-current="page"><li className='photoAlbumNavLink'>{album.title}</li></NavLink>
            )
          })}
        </ul>
    </div>
  )
}

export default NavbarPhotography;
import React from 'react';
import photographyAlbums from "../photographyalbums.json";

const Photography = () => {
  return (
    <section className='photographyNavSection'>

      <div className='photographyNavContainer'>

        {photographyAlbums && photographyAlbums.map((album, index) => {
          return(
          <a href={album.link} key={album.id}>
            <div className='photographyMenuHeadlineBox'>
              <div className='overlay'></div>
              <h2 className='photographyMenuHeadline'>{album.title}</h2>
            </div>
            <div className='photographyMenuImg'>
              <img src={"/photography/" + album.source} alt={album.source} />
            </div>
          </a>
          )})}

      </div>
    </section>
  )
}

export default Photography;
import React, {useState} from 'react'
import NavbarPhotography from '../components/NavbarPhotography';
import procreateImages from "../procreate.json";
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import GalleryModal from '../components/GalleryModal';

const ProcreatePhoto = () => {
  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  

  const handleClick = (photo, index) => {
    setCurrentIndex(index);
    setClickedImage(photo.source);
    console.log(photo);
  }


  const handleRotationRight = () => {
    const totalLength = procreateImages.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = procreateImages[0].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = procreateImages.filter((photo) => {
      return procreateImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };

  const handleRotationLeft = () => {
    const totalLength = procreateImages.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = procreateImages[totalLength - 1].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = procreateImages.filter((photo) => {
      return procreateImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };



  return (
    <div>
      <section className='photoSection'>

        <div className='navbarPhotoDiv'>
          <NavbarPhotography />
        </div>



        <section className='modalSection'>
          {procreateImages && procreateImages && (
            <div className='innerModalDiv'>
              {clickedImage &&
                  <GalleryModal 
                    clickedImage={clickedImage}
                    handleRotationRight={handleRotationRight}
                    handleRotationLeft={handleRotationLeft}
                    setClickedImage={setClickedImage}
                  />
              }
            </div>
          )}
        </section>





        <ResponsiveMasonry
          columnsCountBreakPoints={{700: 2, 900: 3}}
          className='photoContainerBox'>
          <Masonry className='photoContainer'>
            {procreateImages && procreateImages.map((procreate, index) => {
              return(
                <div className='albumPhoto' key={index}>
                  <img
                    onClick={() => handleClick(procreate, index)}
                    src={procreate.source}
                    alt={procreate.title}
                    loading='lazy'
                  />
                </div>
              )
            })}
          </Masonry>
        </ResponsiveMasonry>



      </section>
    </div>
  )
}

export default ProcreatePhoto;
import React, {useState} from 'react'
import NavbarPhotography from '../components/NavbarPhotography';
import coronaImages from "../corona-postcard.json";
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import GalleryModal from '../components/GalleryModal';

const CoronaPhoto = () => {
  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  

  const handleClick = (photo, index) => {
    setCurrentIndex(index);
    setClickedImage(photo.source);
    console.log(photo);
  }

  const handleRotationRight = () => {
    const totalLength = coronaImages.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = coronaImages[0].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = coronaImages.filter((photo) => {
      return coronaImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };

  const handleRotationLeft = () => {
    const totalLength = coronaImages.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = coronaImages[totalLength - 1].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = coronaImages.filter((photo) => {
      return coronaImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };


  return (
    <>
      <section className='photoSection'>

        <div className='navbarPhotoDiv'>
          <NavbarPhotography />
        </div>


        <section className='modalSection'>
          {coronaImages && coronaImages && (
            <div className='innerModalDiv'>
              {clickedImage &&
                  <GalleryModal 
                    clickedImage={clickedImage}
                    handleRotationRight={handleRotationRight}
                    handleRotationLeft={handleRotationLeft}
                    setClickedImage={setClickedImage}
                  />
              }
            </div>
          )}
        </section>


        <ResponsiveMasonry
          columnsCountBreakPoints={{700: 2, 900: 3}}
          className='photoContainerBox'>
          <Masonry className='photoContainer'>
            {coronaImages && coronaImages.map((corona, index) => {
              return(
                <div className='albumPhoto coronaPhoto' key={index}>
                  <img
                    onClick={() => handleClick(corona, index)}
                    src={corona.source}
                    alt={corona.title} />
                </div>
              )
            })}
          </Masonry>
        </ResponsiveMasonry>



      </section>
    </>
  )
}

export default CoronaPhoto;
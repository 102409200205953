import React from "react";
import aboutPhoto from "../assets/aboutphotologo.png";

const About = () => {
  return (
    <div>
      <div className="aboutContainer">
        <div className="aboutMainDiv">
          <div className="aboutTextDiv">
            <p>
              Everything creative, colourful and fun excites me. I've been lucky enough to have had the pleasure of being booked at multiple weddings, workshops, parties, etc. and have enjoyed every part of it. Being a fly on the wall is my favourite superpower. I rarely leave my apartment without any form of camera device - just in case I spot something pretty weird or just plain interesting.<br/>
              As at webdeveloper I've built portfolio websites for some cool people, helped building the burgermenu(yes, that's a term in webdevelopment) for Volume Village, worked hard on my big exam project and some projects to make my own day-to-day life easier. As I am a person who really hates cooking - well, more like deciding WHAT to cook - I gathered a lot of my favourite recipes from various websites in one website VELBEKOM.ME.<br/>
              I would love working with small and upcoming independent companies or solo artists, by helping them get a website up and running, snap cool photos for the website and simply helping out with what I do best!<br/>
              So.. If you're a DJ, artist, dancer, creative, anyone who wants to collaborate or just want to have amazing photos taken  - <i>please, get in touch</i> :-)
            </p>
          </div>
          <div className="aboutImgDiv">
            <img src={aboutPhoto} alt="the photographer and webdeveloper" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
import React, {useState} from 'react';
import jobsProjectsImages from "../jobs-projects.json";
import NavbarPhotography from '../components/NavbarPhotography';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import GalleryModal from '../components/GalleryModal';

const JobsProjects = () => {
  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  

  const handleClick = (photo, index) => {
    setCurrentIndex(index);
    setClickedImage(photo.source);
    console.log(photo);
  }


  const handleRotationRight = () => {
    const totalLength = jobsProjectsImages.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = jobsProjectsImages[0].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = jobsProjectsImages.filter((photo) => {
      return jobsProjectsImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };

  const handleRotationLeft = () => {
    const totalLength = jobsProjectsImages.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = jobsProjectsImages[totalLength - 1].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = jobsProjectsImages.filter((photo) => {
      return jobsProjectsImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };




  return (
    <>
    <section className='photoSection'>

      <div className='navbarPhotoDiv'>
        <NavbarPhotography />
      </div>



      <section className='modalSection'>
          {jobsProjectsImages && jobsProjectsImages && (
            <div className='innerModalDiv'>
              {clickedImage &&
                  <GalleryModal 
                    clickedImage={clickedImage}
                    handleRotationRight={handleRotationRight}
                    handleRotationLeft={handleRotationLeft}
                    setClickedImage={setClickedImage}
                  />
              }
            </div>
          )}
        </section>




      <ResponsiveMasonry
        columnsCountBreakPoints={{700: 2, 900: 3}}
        className='photoContainerBox'>
        <Masonry className='photoContainer'>
          {jobsProjectsImages && jobsProjectsImages.map((jobs, index) => {
            return(
              <div className='albumPhoto' key={index}>
                <img
                  onClick={() => handleClick(jobs, index)}
                  src={jobs.source}
                  alt={jobs.title}
                  loading='lazy'/>
              </div>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>



    </section>
  </>
  )
}

export default JobsProjects;
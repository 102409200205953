import React from 'react'

const Footer = () => {
  return (
    <section className='footer'>
      <a  href="https://www.instagram.com/moodycreatives.jpg/"
          target="_blank"
          rel="noopener noreferrer">@moodycreatives.jpg</a>
      <p>© Inge Moody - All Rights Reserved</p>
      <p>CVR: 44885743</p>
    </section>
  )
}

export default Footer;
import React, { useState } from "react";
import { BsInstagram } from "react-icons/bs";
import {AiOutlineClose} from 'react-icons/ai';
import {HiOutlineMenuAlt4} from 'react-icons/hi';
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [logo, setLogo] = useState(false);

  const handleNav = () => {
    setNav(!nav);
    setLogo(!logo);
  };
  const handleClick = () => setNav(!nav);

  return (
    <section className="navbarSection">
      <nav className="navbar">
        <div>
          <NavLink to="/" aria-current="false">
            <h4>Moody Creatives.</h4>
          </NavLink>
        </div>
        <ul className="navbarUL">
          <NavLink to="/" aria-current="page">
            <li>Coding</li>
          </NavLink>
          <NavLink to="/photography" aria-current="page">
            <li>Photography</li>
          </NavLink>
          <NavLink to="/about" aria-current="page">
            <li>About</li>
          </NavLink>
          <NavLink to="/contact" aria-current="page">
            <li>Contact</li>
          </NavLink>
          <div>
            <a
              href="https://www.instagram.com/moodycreatives.jpg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsInstagram className="icon" size={25} />
            </a>
          </div>
        </ul>

        {/* BURGER MENU */}
        <div className="burgerMenuButton">
          {nav ? (
            <AiOutlineClose onClick={handleNav} className="icon" size={25} />
          ) : (
            <HiOutlineMenuAlt4 onClick={handleNav} className="icon" size={25} />
          )}
        </div>

        {/* MOBIL MENU DROPDOWN */}
        <div className={ nav ? "burgerDropDownShow" : "burgerMenuDropDownHidden" }>

          <div></div>

          <ul className="burgerMenuDropDownUL">
            <NavLink to="/" aria-current="false">
              <h4 className="burgerLogo" onClick={handleClick}>Moody Creatives.</h4>
            </NavLink>

            <NavLink to="/" aria-current="false">
              <li onClick={handleClick}>
                Coding
              </li>
            </NavLink>

            <NavLink to="/photography" aria-current="false">
              <li onClick={handleClick}>Photography</li>
            </NavLink>

            <NavLink to="/about" aria-current="false">
              <li onClick={handleClick}>
                About
              </li>
            </NavLink>

            <NavLink to="/contact" aria-current="false">
              <li onClick={handleClick}>
                Contact
              </li>
            </NavLink>


            <div className="burgerSoMeIcon">
              <a
                href="https://www.instagram.com/moodycreatives.jpg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram className="icon" size={25} />
              </a>
            </div>
          </ul>
        </div>
      </nav>
    </section>
  );
};

export default Navbar;

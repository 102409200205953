
import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Photography from "./pages/Photography";
import Coding from "./pages/Coding";
import AarhusPhoto from "./pages/AarhusPhoto";
import POVPhoto from "./pages/POVPhoto";
import CoronaPhoto from "./pages/CoronaPhoto";
import About from "./pages/About";
import Footer from "./components/Footer";
import ScrollButton from "./components/ScrollButton";
import ProcreatePhoto from "./pages/ProcreatePhoto";
import JobsProjects from "./pages/JobsProjects";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
        <ScrollButton />
      </header>


      <div className="appContent">
        <Routes>
          <Route path="/" element={<Coding />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/photography/aarhus" element={<AarhusPhoto />} />
          <Route path="/photography/jobsprojects" element={<JobsProjects />} />
          <Route path="/photography/pov" element={<POVPhoto />} />
          <Route
            path="/photography/coronapostcards"
            element={<CoronaPhoto />}
          />
          <Route path="/photography/procreate" element={<ProcreatePhoto />} />
        </Routes>
      </div>


        <Footer />
    </div>
  );
}

export default App;

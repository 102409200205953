import React from 'react';
// import contactImg from '../assets/contactimg.png';
// import contactImgMobile from '../assets/contactimgmobile.png';

const Contact = () => {
  return (
    <section className='contactSection'>
      <div className='contactContainer'>
        <div className='contactContainerBox'>
            <div className='contactText'>
                <h4>
                    Get in touch.
                </h4>
                <p>
                    Do you need help building your website? Do you want to bring your portfolio online and show people your amazing work outside instagram? Do you run a shop, bar or restaurant and want to get some cool photos of the aesthetic and the vibe? Are you thinking of getting some cute portraits of your kid?<br/>
                    I'm really want to hear about your crazy plans - whether it be photos for your country album cover, portraits on your new giant sofa, cute portraits for your job application, product photography of cool stuff you want to show off or sell, or the yearly christmas card.<br/>
                    Don't hesitate to get in touch if you want to collaborate in any way :-) Can't wait to hear from you.
                </p>
                <a href="mailto:inge@moodycreatives.com">inge@moodycreatives.com</a>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;
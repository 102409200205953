import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";

const GalleryModal = ({
  clickedImage,
  handleRotationRight,
  setClickedImage,
  handleRotationLeft,
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImage(null);
    }
  };

  return (
    <section
      className="galleryModalSection dismiss"
      onClick={handleClick}
    >
      <div
        onClick={handleRotationLeft}
        className="arrowLeft"
      >
        <IoIosArrowRoundBack size={30}/>
      </div>
      <div className="clickedImage dismiss">
        <img
          src={clickedImage}
          alt="modal billede"
        />
      </div>
      <span className="closeIcon dismiss">
        <IoCloseOutline
          className="dismiss"
          size={30}
        />
      </span>
      <div
        onClick={handleRotationRight}
        className="arrowRight"
      >
        <IoIosArrowRoundForward size={30}/>
      </div>
    </section>
  );
};

export default GalleryModal;

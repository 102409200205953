import React from "react";
import {
  SiHtml5,
  SiJavascript,
  SiTypescript,
  SiGithub,
  SiTailwindcss,
  SiBootstrap,
  SiNextdotjs,
  SiNodedotjs,
  SiMongodb,
  SiGraphql,
  SiDotnet,
  SiWordpress,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiAdobexd,
  SiAdobelightroom,
} from "react-icons/si";
import { DiCss3, DiSass, DiResponsive } from "react-icons/di";
import { BiCameraMovie } from "react-icons/bi";

const CodingSkills = () => {
  return (
    <>
      <h2 className="">SKILLS</h2>

      <section className="codingSkills">
        <div className="codingSkill">
          <div className="iconImgBox">
            <SiHtml5 className="iconImg" size={60} />
          </div>
          <p className="iconTitle">HTML</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <DiCss3 className="iconImg" size={60} />
          </div>
          <p className="iconTitle">CSS</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <DiSass className="iconImg" size={60} />
          </div>
          <p className="iconTitle">SASS</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiJavascript className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Javascript</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiTypescript className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Typescript</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiGithub className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Github</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiTailwindcss className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Tailwind</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiBootstrap className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Bootstrap</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <DiResponsive className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Responsive design</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiNextdotjs className="iconImg" size={60} />
          </div>
          <p className="iconTitle">NEXT.js</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiNodedotjs className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Node.js</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiMongodb className="iconImg" size={60} />
          </div>
          <p className="iconTitle">MongoDB</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiGraphql className="iconImg" size={60} />
          </div>
          <p className="iconTitle">GraphQL</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiDotnet className="iconImg" size={60} />
          </div>
          <p className="iconTitle">.NET</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiWordpress className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Wordpress</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiAdobephotoshop className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Photoshop</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiAdobeillustrator className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Adobe Illustrator</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiAdobexd className="iconImg" size={60} />
          </div>
          <p className="iconTitle">XD</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <SiAdobelightroom className="iconImg" size={60} />
          </div>
          <p className="iconTitle">Lightroom</p>
        </div>

        <div className="codingSkill">
          <div className="iconImgBox">
            <BiCameraMovie className="iconImg" size={60} />
          </div>
          <p className="iconTitle">iMovie</p>
        </div>
      </section>
    </>
  )
}

export default CodingSkills;
import React from 'react';
import {
  SiHtml5,
  SiJavascript,
  SiTypescript,
  SiGithub,
  SiTailwindcss,
  SiBootstrap,
  SiNextdotjs,
  SiNodedotjs,
  SiMongodb,
  SiGraphql,
  SiDotnet,
  SiWordpress,
  SiAdobecreativecloud,
  SiAdobephotoshop,
  SiAdobexd,
  SiAdobelightroom,
} from "react-icons/si";
import { DiCss3, DiSass, DiResponsive } from "react-icons/di";
import { BiCameraMovie } from "react-icons/bi";
import CodingSkills from "../components/CodingSkills";
import sites from "../sites.json";


const convertStringToReactComponent = (name, size) => {
  switch (name) {
    case "html":
      return <SiHtml5 className="iconImg" size={size} />;
    case "css":
      return <DiCss3 className="iconImg" size={size} />;
    case "sass":
      return <DiSass className="iconImg" size={size} />;
    case "javascript":
      return <SiJavascript className="iconImg" size={size} />;
    case "typescript":
      return <SiTypescript className="iconImg" size={size} />;
    case "github":
      return <SiGithub className="iconImg" size={size} />;
    case "tailwind":
      return <SiTailwindcss className="iconImg" size={size} />;
    case "bootstrap":
      return <SiBootstrap className="iconImg" size={size} />;
    case "responsive-design":
      return <DiResponsive className="iconImg" size={size+5} />;
    case "next":
      return <SiNextdotjs className="iconImg" size={size} />;
    case "node":
      return <SiNodedotjs className="iconImg" size={size} />;
    case "mongodb":
      return <SiMongodb className="iconImg" size={size} />;
    case "graphql":
      return <SiGraphql className="iconImg" size={size} />;
    case "dotnet":
      return <SiDotnet className="iconImg" size={size} />;
    case "wordpress":
      return <SiWordpress className="iconImg" size={size} />;
    case "adobe-creative-cloud":
      return <SiAdobecreativecloud className="iconImg" size={size} />;
    case "photoshop":
      return <SiAdobephotoshop className="iconImg" size={size} />;
    case "xd":
      return <SiAdobexd className="iconImg" size={size} />;
    case "lightroom":
      return <SiAdobelightroom className="iconImg" size={size} />;
    case "imovie":
      return <BiCameraMovie className="iconImg" size={size} />;
  }
};


const Coding = () => {
  return (
    <section className='codingPage'>

      <div className='codingHero'>
        <main className='codingMain'>
          {sites && sites.map((site, index) => {
            return(
                <article key={index} className='codingArticle' >
                  <a href={site.url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={site.imgPath}
                        alt={site.headline}
                        id={site.id} />
                  </a>
                  <div className='codingArticleText'>
                    <h5>{site.headline}</h5>
                    <p>{site.description}</p>
                    <div className='codingArticleIcons'>
                      {site.madeWith.split(",").map((name) => convertStringToReactComponent(name, 30))}               
                    </div>
                  </div>
                </article>
            )
          })}
        </main>

        <aside className='codingAside'>
          <CodingSkills />
        </aside>

      </div>


    </section>
  )
}

export default Coding;
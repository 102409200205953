import React, {useState} from 'react'
import NavbarPhotography from '../components/NavbarPhotography';
import povImages from "../pov.json";
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import GalleryModal from '../components/GalleryModal';

const POVPhoto = () => {
  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  

  const handleClick = (photo, index) => {
    setCurrentIndex(index);
    setClickedImage(photo.source);
    console.log(photo);
  }


  const handleRotationRight = () => {
    const totalLength = povImages.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = povImages[0].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = povImages.filter((photo) => {
      return povImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };

  const handleRotationLeft = () => {
    const totalLength = povImages.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = povImages[totalLength - 1].source;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = povImages.filter((photo) => {
      return povImages.indexOf(photo) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };




  return (
    <>
      <section className='photoSection'>

        <div className='navbarPhotoDiv'>
          <NavbarPhotography />
        </div>




        <section className='modalSection'>
          {povImages && povImages && (
            <div className='innerModalDiv'>
              {clickedImage &&
                  <GalleryModal 
                    clickedImage={clickedImage}
                    handleRotationRight={handleRotationRight}
                    handleRotationLeft={handleRotationLeft}
                    setClickedImage={setClickedImage}
                  />
              }
            </div>
          )}
        </section>




        <ResponsiveMasonry
          columnsCountBreakPoints={{700: 2, 900: 3}}
          className='photoContainerBox'>
          <Masonry className='photoContainer'>
            {povImages && povImages.map((pov, index) => {
              return(
                <div className='albumPhoto' key={index}>
                  <img
                    onClick={() => handleClick(pov, index)}
                    src={pov.source}
                    alt={pov.title} />
                </div>
              )
            })}
          </Masonry>
        </ResponsiveMasonry>



      </section>
    </>
  )
}

export default POVPhoto;